import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Element } from "react-scroll";
import "./global.css";
import ReactGA from "react-ga4";
import Header from "./components/sections/header";
import Footer from "./components/sections/footer";
import Metrics from "./components/sections/metrics";
import GraphicPreparation from "./components/sections/technologies";
import FAQ from "./components/sections/faq";
import Gallery from "./components/sections/gallery";
import Features from "./components/sections/features";
import Hero from "./components/sections/hero";
import Services from "./components/sections/services";
import TermsOfService from "./components/pages/terms_of_service";
import PrivacyPolicy from "./components/pages/privacy_policy";
import CookiesPolicy from "./components/pages/cookies";
import Contact from "./components/pages/contact";
import PaymentPolicy from "./components/pages/payment_policy";
import DeliveryPolicy from "./components/pages/delivery_policy";
import Screenprint from "./components/pages/screenprint";
import ScreenprintThermotransfer from "./components/pages/screenprint_thermotransfer";
import Dtf from "./components/pages/dtf";
import Sublimation from "./components/pages/sublimation";
import Order from "./components/pages/order";
import NotFound from "./components/pages/not_found";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./components/ui/alert-dialog";

import nexprim_redirect from "./assets/svg/nexprim_redirect.svg";
import { Switch } from "./components/ui/switch";
import { TranslationProvider } from "./TranslationContext";
import sk from "./locales/sk.json";
import en from "./locales/en.json";
import cz from "./locales/cz.json";
import de from "./locales/de.json";

const languageMap: { [key: string]: typeof sk } = {
  SK: sk,
  CZ: cz,
  DE: de,
  EN: en,
  default: sk,
};

const getLanguage = (): typeof sk => {
  // Check for language cookie first
  const langCookie = Cookies.get("lang");
  if (langCookie && languageMap[langCookie]) {
    return languageMap[langCookie];
  }

  return languageMap["default"];
};


declare global {
  interface Window {
    init_ga: () => void;
    init_gtm: () => void;
  }
}

function InnerApp() {
  const [translations, setTranslations] = useState(sk);

  useEffect(() => {
    const selectedLanguage = getLanguage();
    console.log(selectedLanguage);
    setTranslations(selectedLanguage);
  }, []);

  // Add these states for controlling the AlertDialogs
  const [isFirstDialogOpen, setFirstDialogOpen] = useState(true);

  // Define the handleAcceptAll function
  const redirectToNexprim = () => {
    window.location.href = "https://nexprim.com";
  };

  return (
    <TranslationProvider translations={translations}>
      <div className="font-outfit">
        <AlertDialog open={true} onOpenChange={setFirstDialogOpen}>
          <AlertDialogContent className="font-outfit">
            <AlertDialogHeader>
              <AlertDialogTitle>
                {translations.app.redirect.title}
              </AlertDialogTitle>
              <AlertDialogDescription className="text-base">
                {translations.app.redirect.description}
              </AlertDialogDescription>
              <img
                className="relative w-2/4 pt-10 pb-6 sm:w-1/2"
                alt="Q&R is now Nexprim"
                src={nexprim_redirect}
              />
              <AlertDialogDescription className="text-center text-base opacity-80 pb-4">
                {translations.app.redirect.changeSettings}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction
                onClick={redirectToNexprim}
              >
                {translations.app.redirect.acceptAll}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        {/* <LandingPage /> */}
        <Header />
        <div className="App" id="App">
          <Routes>
            <Route
              path="/order"
              element={
                <>
                  {" "}
                  <Order />{" "}
                </>
              }
            />
            <Route
              path="/terms-of-service"
              element={
                <>
                  {" "}
                  <TermsOfService />{" "}
                </>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies" element={<CookiesPolicy />} />
            <Route path="/payment-policy" element={<PaymentPolicy />} />
            <Route path="/delivery-policy" element={<DeliveryPolicy />} />
            <Route path="/screenprint" element={<Screenprint />} />
            <Route
              path="/screenprint_thermotransfer"
              element={<ScreenprintThermotransfer />}
            />
            <Route path="/sublimation" element={<Sublimation />} />
            <Route path="/dtf" element={<Dtf />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Features />
                  <Element name="metrics">
                    <Metrics />
                  </Element>
                </>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </TranslationProvider>
  );
}

function App() {
  return (
    <Router>
      <InnerApp />
    </Router>
  );
}

export default App;
